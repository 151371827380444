export const UserRoleKey = {
  ADMINISTRATOR: 'ADMINISTRATOR',
  SALON_OWNER: 'SALON_OWNER',
  MANAGER: 'MANAGER',
  STAFF: 'STAFF',
  CUSTOMER: 'CUSTOMER',
};

export const StatusBookingKey = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  ARRIVED: 'ARRIVED',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  UNCONFIRMED: 'UNCONFIRMED',
};

export enum EnumStatusBooking {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  ARRIVED = 'ARRIVED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  UNCONFIRMED = 'UNCONFIRMED',
}

export enum PaymentMethod {
  CREDIT = 'CREDIT',
  CASH = 'CASH',
  DEBIT = 'DEBIT',
  VISA = 'VISA',
  MASTER = 'MASTER',
  AMEX = 'AMEX',
  PAYMENT_APP = 'PAYMENT_APP',
  MEMBER_CARD = 'MEMBER_CARD',
  GIFT_CARD = 'GIFT_CARD',
  CLOVER = 'CLOVER',
  MERCHANT_TERMINAL = 'MERCHANT_TERMINAL',
  MERCHANT_EXTERNAL = 'MERCHANT_EXTERNAL',
  CARD_ON_FILE = 'CARD_ON_FILE',
}

export enum PaymentStatus {
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PENDING = 'PENDING',
}

export enum EnumGender {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}

export enum PaymentGateWay {
  CLOVERTERMINAL = 'CLOVERTERMINAL',
  CLOVERECOMMERCE = 'CLOVERECOMMERCE',
  EXTERNAL = 'EXTERNAL',
}

export enum ConfimEnumType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum MembershipClassificationEnum {
  ALL = 'ALL',
  REGULAR = 'REGULAR',
  CATEGORY = 'CATEGORY',
}

export enum enumEventSocketGeneral {
  UPDATE_AGREEMENT = 'update-agreement',
  NEW_BOOKING = 'new-booking-dashboard',
  UPDATE_BOOKING = 'update-booking-dashboard',
}

export enum enumActionHistoryLog {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  CHANGE_STATUS = 'CHANGE_STATUS',
  CONFIRM = 'CONFIRM',
  CANCEL = 'CANCEL',
}

export enum enumTableHistoryLog {
  EMPLOYEE = 'EMPLOYEE',
  CUSTOMER = 'CUSTOMER',
  SERVICE_CATEGORY = 'SERVICE_CATEGORY',
  SERVICE = 'SERVICE',
  ADD_ON = 'ADD_ON',
  STORE_BRANCH = 'STORE_BRANCH',
  DISCOUNT_CODE = 'DISCOUNT_CODE',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  REWARD_CONFIG = 'REWARD_CONFIG',
  BOOKING = 'BOOKING',
}

export enum EnumLoopType {
  NO_LOOP = 'NO_LOOP', // No repetition of the special date.
  MONTHLY = 'MONTHLY', // The special date repeats monthly.
  YEARLY = 'YEARLY', // The special date repeats yearly.
}

export enum EnumTypeAgreementMembership {
  FORM_1 = 'FORM_1',
}
