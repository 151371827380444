import axiosClient from '@/helper/call-center';
import { IStoreBranchCreate } from '@/interface/store-branch';
import { EnumLoopType } from '@/utils/enum';

export const StoreBranchApi = {
  async getAll() {
    const response = await axiosClient.get('/store-branch');
    return response.data;
  },
  async findAllByRole() {
    const response = await axiosClient.get('/store-branch/find-all-by-role');
    return response.data;
  },
  findByPaginate(paginate: { current: number; limit: number; textSearch?: string }) {
    return axiosClient.get('/store-branch/paginate', {
      params: paginate,
    });
  },
  findById(id: string) {
    return axiosClient.get(`/store-branch/${id}`);
  },
  create(data: IStoreBranchCreate) {
    return axiosClient.post('/store-branch', data);
  },
  update(id: string, data: IStoreBranchCreate) {
    return axiosClient.put(`/store-branch/${id}`, data);
  },
  delete(id: string) {
    return axiosClient.delete(`/store-branch/${id}`);
  },
  createWorkTime(data: any) {
    return axiosClient.post('/store-branch/create-work-time', data);
  },
  updateWorkTime(id: string, data: any) {
    return axiosClient.put(`/store-branch/work-time/${id}`, data);
  },
  deleteWorkTime(id: string) {
    return axiosClient.delete(`/store-branch/work-time/${id}`);
  },
  specialDatesByLocation(location: string) {
    return axiosClient.get(`/store-branch/special-date/${location}`);
  },
  createSpecialDate(
    location: string,
    payload: {
      date: string;
      slots: number;
      loop: EnumLoopType;
      time_open: string;
      time_close: string;
    },
  ) {
    return axiosClient.post(`/store-branch/special-date/${location}`, payload);
  },
  updateSpecialDate(
    location: string,
    specialDateId: string,
    payload: {
      date: string;
      slots: number;
      loop: EnumLoopType;
      time_open: string;
      time_close: string;
    },
  ) {
    return axiosClient.put(`/store-branch/special-date/${location}/by-id/${specialDateId}`, payload);
  },
  deleteSpecialDate(id: string) {
    return axiosClient.delete(`/store-branch/special-date/by-id/${id}`);
  },
};
